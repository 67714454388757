<template>
  <div v-loading.fullscreen.lock="fullscreenLoading">
    <div class="excel-container">
      <div class="w-full">
        <div class="flex flex-wrap justify-end">
          <div class="flex flex-row py-2">
            <span class="label">Search:</span>
            <el-input v-model="params.searchValue" size="mini" clearable ></el-input>
          </div>
          <div class="flex flex-row py-2">
            <span class="label">Start Date:</span>
            <el-date-picker v-model="params.startDate" size="mini" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleDateChange">
            </el-date-picker>
          </div>
          <div class="flex flex-row py-2">
            <span class="label">End Date:</span>
            <el-date-picker v-model="params.endDate" size="mini" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" @change="handleDateChange">
            </el-date-picker>
          </div>
          <div class="flex flex-row pl-2 py-2">
            <el-button size="mini" type="primary" @click="handleSearch">Search</el-button>
            <el-button size="mini" type="primary" @click="handleExportExcel">Export Excel</el-button>
            <el-button size="mini" type="primary" @click="handleAddRecordDialog">Add a Record</el-button>
          </div>
        </div>
      </div>
      <div class="w-full">
        <el-table :data="data">
          <el-table-column width="50" :label="$t('table.no')">
            <template slot-scope="scope">{{ scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column width="200" prop="companyName" label="Co."></el-table-column>
          <el-table-column width="200" prop="bargeName" label="Barge Name"></el-table-column>
          <el-table-column width="150" prop="date" label="Date"></el-table-column>
          <el-table-column width="150" prop="beforeLoading" label="Before Loading">
            <template slot-scope="scope">
              <div :style="scope.row.notMatched ? { background: 'yellow' } : {}">
                {{ scope.row.beforeLoading }}
              </div>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="afterLoading" label="After Loading"></el-table-column>
          <el-table-column width="150" prop="totalLoaded" label="Total Loaded"></el-table-column>
          <el-table-column width="150" prop="shipFigure" label="Ship Figure GKL"></el-table-column>
          <!--el-table-column width="150" prop="tankGauge" label="Tank Gauge GKL"></el-table-column>
          <el-table-column width="200" prop="meterAgainstTankGauge" label="Meter Against Tank Gauge GKL"></el-table-column-->
          <el-table-column width="150" prop="jl" label="JL"></el-table-column>
          <el-table-column width="150" prop="hoseNo" label="Hose No."></el-table-column>
          <el-table-column width="150" prop="remarks" label="Tank"></el-table-column>
          <!--el-table-column width="150" prop="density" label="Density"></el-table-column>
          <el-table-column width="150" prop="viscosity" label="Viscosity"></el-table-column>
          <el-table-column width="150" prop="billingTemp" label="Billing Temp"></el-table-column>
          <el-table-column width="150" prop="jtyTemp" label="Jty Temp"></el-table-column-->
          <el-table-column width="150" prop="rob" label="R.O.B"></el-table-column>
          <el-table-column width="150" prop="lineContent" label="Line Content Added"></el-table-column>
          <el-table-column width="200" prop="shipUllageFigure" label="Ship’s Ullage Figure (MT)"></el-table-column>
          <!--el-table-column width="250" prop="shipMassFlowMeterFigure" label="Ship’s Mass Flow Meter Figure (MT)"></el-table-column-->
          <el-table-column width="200" prop="terminalFigure" label="Terminal’s Figure (MT)"></el-table-column>
          <!--el-table-column label="Variance with ship’s mass flow meter">
            <el-table-column width="120" prop="varianceWithShipMassFlowMeterMetricTon" label="Metric Ton"></el-table-column>
            <el-table-column width="120" prop="varianceWithShipMassFlowMeterPercentage" label="%"></el-table-column>
          </el-table-column-->
          <el-table-column label="Variance with ship declared figure">
            <el-table-column width="120" prop="varianceWithShipDeclaredFigureMetricTon" label="Metric Ton"></el-table-column>
            <el-table-column width="120" prop="varianceWithShipDeclaredFigurePercentage" label="%"></el-table-column>
          </el-table-column>
          <el-table-column label="Action"  width="100" align="left">
              <tempate slot-scope="scope">
                <el-button v-if="scope.row.loadedId > 0" type="primary" size="mini" plain @click="handleRemove(scope.row.loadedId)">Remove</el-button>
            </tempate>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex justify-end">
        <pagination
          v-show="total > 0"
          style="background: #f2f7fa"
          :total="total"
          :page.sync="params.pageNum"
          :limit.sync="params.pageSize"
          @pagination="handlePagechange"
        ></pagination>
      </div>
    </div>

    <el-dialog title="Add a Record" :visible.sync="addRecordDialog" :width="isMobileScreen ? '100%' : '450px'" @close="handleResetAddRecordForm">
      <div class="px-10 addRecordForm">
        <el-form :model="form" size="mini" :inline="false"  ref="formData" :rules="rules">
          <el-form-item label="Before Loading" prop="beforeLoading">
            <el-input type="number" v-model="form.beforeLoading" clearable/>
          </el-form-item>
          <el-form-item label="After Loading" prop="afterLoading">
            <el-input type="number" v-model="form.afterLoading" clearable/>
          </el-form-item>
          <br v-if="longErrorMessage" />
          <el-form-item label="Datetime" prop="dateTime">
            <el-date-picker v-model="form.dateTime" size="mini" prefix-icon="el-icon-date" type="datetime" :style="{ width: '100%' }"
              format="dd/MM/yyyy HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="Jetty No." prop="jettyNo">
            <el-select v-model="form.jettyNo" style="width: 100%;" :disabled="disabled" size="mini" @change="getHoseByJetty">
              <el-option v-for="(jetty) in jettyOption" :key="jetty" :label="jetty" :value="jetty"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Hose" prop="hose">
            <el-select v-model="form.hose" style="width: 100%;" :disabled="disabled" size="mini">
              <el-option v-for="(hose) in hoseOption" :key="hose" :label="hose" :value="hose"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Barge Name" >
            <el-input value="CALIBRATION" disabled/>
          </el-form-item>
          <div class="flex justify-end pr-1">
            <el-form-item class="justify-end">
              <el-button @click="handleResetAddRecordForm()">Cancel</el-button>
              <el-button type="primary" @click="handleAddRecord()">Add Record</el-button>
            </el-form-item>
          </div>
          
        </el-form>
      </div>
    </el-dialog>   
  </div>
</template>
<script>
import {getNominationExcelList, exportNominationExcelList, addToNominationExcelList, removeLogisticsExcelCallibrationRecord} from "@/services";
import { jettyHoseList } from "@/utils/constants";
export default {
  name: "Excel",
  data() {
    let validateAfterLoading = (rule, value, callback) => {
      if(Number(value) < Number(this.form.beforeLoading)) {
        this.longErrorMessage = true
        callback('Must be equal or higher than the Before Loading value')
      } else {
        this.longErrorMessage = false
        callback()
      }
    };
    return {
      params: {
        searchValue: "",
        pageNum: 1,
        pageSize: 20,
        startDate: null,
        endDate: null,
      },
      fullscreenLoading: false,
      total: 0,
      data: [],
      addRecordDialog: false,
      form: {
        beforeLoading: null,
        afterLoading: null,
        dateTime: null,
        jettyNo: null,
        hose: null,
      },
      jettyOption:[],
      hoseOption:[],
      longErrorMessage: false,
      rules: {
        beforeLoading: [{required: true, message: 'Cannot be empty', trigger: 'blur'},],
        afterLoading: [
          {required: true, message: 'Cannot be empty', trigger: 'blur'},
          {validator: validateAfterLoading, trigger: 'blur'}
        ],
        dateTime: [{required: true, message: 'Cannot be empty', trigger: 'blur'},],
        jettyNo: [{required: true, message: 'Cannot be empty', trigger: 'blur'},],
        hose: [{required: true, message: 'Cannot be empty', trigger: 'blur'},],
      },
    }
  },
  created() {
    // this.handleSearch();
    this.jettyOption = jettyHoseList.map(item => item.jettyNo);
  },
  methods: {
    handleDateChange(val) {

    },
    handlePagechange(object) {
      this.params.pageNum = object.page
      this.params.pageSize = object.limit
      this.handleSearch()
    },
    handleSearch() {
      getNominationExcelList(this.params).then(res => {
        this.data = res.data.table.rows
        this.total = res.data.table.total
      })
    },
    handleExportExcel() {
      let params = {
        searchValue: this.params.searchValue,
        startDate: this.params.startDate,
        endDate: this.params.endDate,
      }
      exportNominationExcelList(params)
    },
    handleAddRecordDialog(){
      this.addRecordDialog = true;
    },
    getHoseByJetty(jettyNo) {
      const jetty = jettyHoseList.find(item => item.jettyNo === jettyNo);
      this.hoseOption = jetty ? jetty.hoses.sort() : [];
    },
    async handleAddRecord(){
      const data = {
        ...this.form,
      };
      const isValid = await new Promise((resolve) => {
        this.$refs.formData.validate((valid) => {
          if (!valid) {
            resolve(false);
          } else {
            resolve(true);
          }
        });
      });

      if (!isValid) {
        // this.loading = false;
        return;
      }
      try {
        const res = await addToNominationExcelList(data);
        if(res.code == 200){
          this.$message.success('Add New Record Success');
          this.handleResetAddRecordForm();
          await this.handleSearch();
        }
       
      } catch (error) {
      } finally {
      }
    },
    handleResetAddRecordForm() {
      this.$refs.formData.clearValidate();
      this.form = {
        beforeLoading: '',
        afterLoading: '',
        datetime: '',
        jettyNo: '',
        hose: '',
        bargeName: ''
      };
      this.hoseOption = [];
      this.longErrorMessage = false;
      this.addRecordDialog = false;
    },
    async handleRemove(loadedId){
      // this.loading = true

      await removeLogisticsExcelCallibrationRecord( loadedId ).then(async res => {
        if(res.code == 200){
          this.$message.success('Remove Callibration Record Success')
        }
      }).finally(() => {
        this.handleSearch()
      })

    },
  }
}
</script>
<style scoped>
.excel-container {
  width: 100% !important;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #D5DDED;
  font-size: 11px;
  color: #1D1B20;
  font-family: 'Roboto';
  padding: 20px;

  .label {
    padding: 5px;
    color: #1D1B20;
    font-weight: 600;
    text-align: right;
    width: 90px;
  }
}
/* ::v-deep .el-form {
  display: flex;
} */
::v-deep .el-form-item {
  width: 300px;
  display: flex;
  padding-right: 10px;
}
::v-deep .el-form-item__label {
  width: 100px;
  text-align: left;
  font-size: 12px;
  color: #1D1B20;
  padding: 0px 0px 0px 0px;
  /* font-family: 'Roboto'; */
  /* font-weight: 600; */
}
::v-deep .el-form-item__content {
  width: 190px;
  text-align: left;
}
::v-deep .el-button {
  font-size: 12px;
}
::v-deep.el-table thead.is-group th.el-table__cell {
  background-color: #F4F6FA;
  border: 1px solid #D5DDED;
}
::v-deep.el-table th.el-table__cell {
  padding: 3px 0px;
}
::v-deep.el-table th.el-table__cell > .cell {
  font-size: 12px;
  color: #1D1B20;
  font-family: 'Roboto';
  font-weight: 900;
  text-align: center;
}

::v-deep .el-table__row > .el-table__cell > .cell {
  color: #1D1B20 !important;
}
::v-deep .el-table__row td {
  border-bottom: 1px solid #D5DDED;
}
::v-deep .el-form-item__error{
  word-break: break-word;
}
</style>